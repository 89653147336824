import { formatJSON, tryParseJSONObject } from '@/utils/check'
import { Button, Popconfirm, Tabs } from 'antd'
import React, { useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthorFormModal, {
  modalAuthorInterface,
} from 'src/components/book/AuthorFormModal'
import AuthorTable from 'src/components/book/AuthorTable'
import BookTable from 'src/components/book/BookTable'
import DurationFormModel, {
  modalDurationInterface,
} from 'src/components/book/DurationFormModel'
import withChangeHeader from 'src/components/hoc/withChangeHeader'
import MountedModalProvider from 'src/components/modal/MountedModalProvider'
import AuthorService from 'src/services/authorService'
import CategoryService from 'src/services/categoryService'
import PresetPriceService from 'src/services/presetPriceService'
import { getIdentifier } from '@/utils'
import handleError from 'src/utils/errorHelper'
import CategoryFormModal, {
  modalCategoryInterface,
} from '../../components/book/CategoryFormModal'
import CategoryTable from '../../components/book/CategoryTable'
import TableActionMenu, {
  TableActionMenuProps,
} from '../../components/table/TableActionMenu'
import { config } from '../../constants'
import BookService from '@/services/bookService'
import { DashboardStats } from '@/components'
import useIsAuthor from '@/components/hooks/useIsAuthor'
import useIsAdmin from '@/components/hooks/useIsAdmin'

const categoryService = new CategoryService()
const authorService = new AuthorService()
const presetPriceService = new PresetPriceService()
const bookService = new BookService()

const mapCategoryDataToSubmit = (data: any) => {
  let { name, color, avatar } = data
  data = {
    name,
    config: {
      color,
      avatar,
    },
  }

  return data
}

const defaultCategoryFormData = {
  name: '',
  color: config.defaultColorPicker,
}

const defaultAuthorFormData = {
  name: '',
}

const defaultDurationFormData = {
  name: '',
}

const BookPage: React.FC = () => {
  const [refreshToken, setRefreshToken] = useState(undefined)

  const onRefresh = () => {
    setRefreshToken(Math.random().toString())
  }

  const isAuthor = useIsAuthor()
  const isAdmin = useIsAdmin()

  const [modalCategoryState, setModalCategoryState] =
    useState<modalCategoryInterface>({
      open: false,
      onSubmit: async () => {},
      formData: {
        name: '',
        color: config.defaultColorPicker,
      },
    })

  const [modalAuthorState, setModalAuthorState] = useState<modalAuthorInterface>({
    open: false,
    onSubmit: async () => {},
    formData: {
      name: '',
    },
    isChangePassword: false,
  })

  const [modalDurationState, setModalDurationState] =
    useState<modalDurationInterface>({
      open: false,
      onSubmit: async () => {},
      formData: {
        name: '',
        detail: [
          {
            id: '',
            price: {
              value: 0,
              currency: 'USD',
            },
            duration: 0,
            durationType: 'Day',
            // presetCode: '',
          },
        ],
      },
    })

  const categoryRefetchRef = useRef<Function>()

  const setModalCategoryForCreate = useCallback(() => {
    setModalCategoryState((old) => ({
      ...old,
      open: true,
      title: 'Create Category',
      onSubmit: async (data) => {
        data = mapCategoryDataToSubmit(data)
        try {
          await categoryService.create(data)
          setModalCategoryState((old) => ({ ...old, open: false }))
          categoryRefetchRef.current()

          onRefresh()
        } catch (e) {
          handleError(e)
        }
      },
      onCancel: () => {
        setModalCategoryState({ ...old, open: false })
      },
      formData: defaultCategoryFormData,
    }))
  }, [modalCategoryState, categoryRefetchRef])

  const setModalCategoryForUpdate = useCallback(
    (record: any) => {
      const { name, config } = record

      setModalCategoryState((old) => ({
        ...old,
        open: true,
        title: 'Update Category',
        onSubmit: async (data) => {
          data = mapCategoryDataToSubmit(data)
          try {
            await categoryService.update({ id: record[getIdentifier()], data })
            setModalCategoryState((old) => ({
              ...old,
              open: false,
            }))
            categoryRefetchRef.current()
          } catch (e) {
            handleError(e)
          }
        },

        onCancel: () => {
          setModalCategoryState({
            ...old,

            formData: defaultCategoryFormData,
            open: false,
          })
        },
        formData: {
          name,
          color: config.color ? config.color : config.defaultColorPicker,
          avatar: config.avatar || '',
        },
      }))
    },
    [modalCategoryState, categoryRefetchRef],
  )

  const authorRefetchRef = useRef<Function>()

  const setModalAuthorForCreate = useCallback(() => {
    setModalAuthorState((old) => ({
      ...old,
      open: true,
      title: 'Create Author',
      onSubmit: async (data) => {
        try {
          await authorService.create({
            ...data,
            configs: tryParseJSONObject(data.configs),
          })
          setModalAuthorState((old) => ({
            ...old,
            open: false,
          }))
          authorRefetchRef.current()

          onRefresh()
        } catch (e) {
          handleError(e)
        }
      },
      onCancel: () => {
        setModalAuthorState(() => {
          return { ...old, open: false, isChangePassword: false }
        })
      },
      formData: defaultAuthorFormData,
    }))
  }, [modalAuthorState, authorRefetchRef])

  const setModalAuthorForChangePassword = useCallback(
    (record) => {
      console.log("record",record)
      // Button change password author
      setModalAuthorState((old) => ({
        ...old,
        open: true,
        title: record?.hasPassword
          ? 'Change Password Author'
          : 'Add Password Author',

        onSubmit: async (data) => {
          console.log("Papa",data)
          try {
            await authorService.changePassword({
              data: { password: data.password, confirmPassword: data.confirmPassword },
              id: record[getIdentifier()],
            })
            setModalAuthorState((old) => ({
              ...old,
              open: false,
              isChangePassword: false,
            }))
            authorRefetchRef.current()
          } catch (e) {
            handleError(e)
          }
        },
        onCancel: () => {
          setModalAuthorState(() => {
            return { ...old, open: false, isChangePassword: false }
          })
        },
        formData: {
          name: '',
        },
        isChangePassword: true,
      }))
    },
    [modalAuthorState, authorRefetchRef],
  )

  const setModalAuthorForUpdate = useCallback(
    (record) => {
      const {
        name,
        avatar,
        bio,
        email,
        phone,
        salutation,
        socialLinks,
        memo,
        username,
        configs,
      } = record

      setModalAuthorState((old) => ({
        ...old,
        open: true,
        title: 'Update Author',
        onSubmit: async (data) => {
          try {
            await authorService.update({
              data: { ...data, configs: tryParseJSONObject(data.configs) },
              id: record[getIdentifier()],
            })
            setModalAuthorState((old) => ({
              ...old,
              open: false,
            }))
            authorRefetchRef.current()
          } catch (e) {
            handleError(e)
          }
        },
        onCancel: () => {
          setModalAuthorState(() => {
            return { ...old, open: false }
          })
        },
        formData: {
          name,
          avatar,
          bio,
          email,
          phone,
          salutation,
          socialLinks,
          memo,
          username,
          configs: formatJSON(JSON.stringify(configs)),
        },
      }))
    },
    [modalAuthorState, authorRefetchRef],
  )

  const durationRefetchRef = useRef<Function>()

  const setModalDurationForCreate = useCallback(() => {
    setModalDurationState((old) => ({
      ...old,
      open: true,
      title: 'Create Duration',
      onSubmit: async (data) => {
        await presetPriceService.create(data)
        setModalDurationState((old) => ({ ...old, open: false }))
        durationRefetchRef.current()

        onRefresh()
      },
      onCancel: () => {
        setModalDurationState({ ...old, open: false })
      },
      formData: defaultDurationFormData,
    }))
  }, [modalDurationState, durationRefetchRef])

  const setModalDurationForUpdate = useCallback(
    (record) => {
      setModalDurationState((old) => ({
        ...old,
        open: true,
        title: 'Update Duration',
        onSubmit: async (data) => {
          await presetPriceService.update({ id: record[getIdentifier()], data })
          setModalDurationState((old) => ({ ...old, open: false }))
          durationRefetchRef.current()
        },
        onCancel: () => {
          setModalDurationState(() => {
            return { ...old, open: false }
          })
        },
        formData: record,
      }))
    },
    [modalDurationState, durationRefetchRef],
  )

  const renderCategoryActionMenu = (args: any) => {
    const { record } = args
    let menus: TableActionMenuProps['menus'] = [
      {
        render: () => {
          return <div>Edit</div>
        },
        key: 'Edit',
        onClick: () => {
          setModalCategoryForUpdate(record.record)
        },
      },
      {
        render: () => {
          return <div>Delete</div>
        },
        key: 'Delete',
        onClick: async () => {
          try {
            await categoryService.delete(record.record[getIdentifier()])
            categoryRefetchRef.current()

            onRefresh()
          } catch (e) {
            handleError(e)
          }
        },
      },
    ]

    return <TableActionMenu menus={menus} />
  }

  const renderAuthorActionMenu = (args: any) => {
    const { record, mutate } = args
    let menus: TableActionMenuProps['menus'] = [
      {
        render: () => {
          return <div>Edit</div>
        },
        key: 'Edit',
        onClick: () => {
          setModalAuthorForUpdate(record.record)
        },
      },
      {
        render: () => {
          return (
            <div>
              {record.record?.hasPassword ? 'Change Password' : 'Add Password'}
            </div>
          )
        },
        key: 'Change Password',
        onClick: () => {
          setModalAuthorForChangePassword(record.record)
        },
      },
      {
        render: () => {
          return (
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={async () => {
                try {
                  await authorService.delete(record.record[getIdentifier()])
                  authorRefetchRef.current()

                  onRefresh()
                } catch (e) {
                  handleError(e)
                }
              }}
            >
              <div>Delete</div>
            </Popconfirm>
          )
        },
        key: 'Delete',
      },
    ]

    return <TableActionMenu menus={menus} />
  }

  const renderDurationActionMenu = (args: any) => {
    const { record, mutate, data } = args
    let menus: TableActionMenuProps['menus'] = [
      {
        render: () => {
          return <div>Edit</div>
        },
        key: 'Edit',
        onClick: () => {
          setModalDurationForUpdate(record.record)
        },
      },
      {
        render: () => {
          return <div>Delete</div>
        },
        key: 'Delete',
        onClick: async () => {
          try {
            await presetPriceService.delete(record.record[getIdentifier()])
            const newData = data.filter(
              (item: any) =>
                item[getIdentifier()] !== record.record[getIdentifier()],
            )
            mutate(newData)
            durationRefetchRef.current()

            onRefresh()
          } catch (e) {
            handleError(e)
          }
        },
      },
    ]

    return <TableActionMenu menus={menus} />
  }

  const navigate = useNavigate()

  let tabPanes = [
    {
      key: '1',
      children: (
        <BookTable
          renderCreateButton={() => {
            return (
              <Button
                type="primary"
                onClick={() => {
                  let url = isAuthor
                    ? '/author/books/create/new-book'
                    : '/books/create/new-book'

                  navigate(url)
                }}
              >
                Create Book
              </Button>
            )
          }}
          renderOwnActionMenu={() => null}
          onRefresh={onRefresh}
        />
      ),
      label: 'Books',
    },
    {
      key: '2',
      children: (
        <CategoryTable
          refetchRef={categoryRefetchRef}
          renderCreateButton={() => {
            if (!isAdmin) {
              return <React.Fragment />
            }

            return (
              <Button type="primary" onClick={() => setModalCategoryForCreate()}>
                Create Category
              </Button>
            )
          }}
          renderOwnActionMenu={(args) => {
            return renderCategoryActionMenu(args)
          }}
          onRefresh={onRefresh}
        />
      ),
      label: 'Categories',
    },
  ]

  if (isAdmin) {
    tabPanes = [
      ...tabPanes,
      {
        key: '3',
        children: (
          <AuthorTable
            refetchRef={authorRefetchRef}
            renderCreateButton={() => {
              return (
                <Button type="primary" onClick={() => setModalAuthorForCreate()}>
                  Create Author
                </Button>
              )
            }}
            renderOwnActionMenu={(args) => {
              return renderAuthorActionMenu(args)
            }}
            onRefresh={onRefresh}
          />
        ),
        label: 'Authors',
      },
    ]
  }

  return (
    <>
      <MountedModalProvider
        shouldMounted={
          modalCategoryState.open || modalAuthorState.open || modalDurationState.open
        }
      >
        {modalCategoryState.open && <CategoryFormModal {...modalCategoryState} />}
        {modalAuthorState.open && <AuthorFormModal {...modalAuthorState} />}
        {modalDurationState.open && <DurationFormModel {...modalDurationState} />}
      </MountedModalProvider>

      <DashboardStats
        service={bookService}
        defaultProps={{}}
        refreshToken={refreshToken}
      />

      <br />

      <Tabs defaultActiveKey="1" items={tabPanes} />
    </>
  )
}
export default withChangeHeader(BookPage, 'Book Management')
