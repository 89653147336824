import { ArrowLeftOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import FormPageContainer, { FormPageHeaderText } from '../other/FormPageContainer'
import { useNavigate, useParams } from 'react-router-dom'
import BookService from 'src/services/bookService'
import { getIdentifier } from '@/utils'
import styled from 'styled-components'
import { dateTimeformat, formatType } from 'src/utils/dateTime'

const bookService = new BookService()

const Container = styled.div``

const BookTitle = styled.h4`
  ${({ theme }) => theme.h4};
  letter-spacing: 0.02em;
  margin-bottom: 0;
  font-size: 26px;
`

const BookContainer = styled.div`
  display: grid;
  margin-top: 35px;
  grid-template-columns: 200px 47px auto;
`

const BookInfoLeftContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &>div: first-child {
    margin-bottom: 25px;
  }

  &>div: not(: first-child) {
    margin-top: 20px;
  }
`

const BookInfoContainer = styled.div`
  width: 100%;
  position: relative;
  grid-column: 3/4;
`

const BookCover = styled.img`
  object-fit: cover;
  width: 200px;
  height: auto;
  margin-right: 16px;
  cursor: pointer;
`

const BookCoverContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`

export const BookCoverBackground = styled.div`
  background: #eee;
  width: 276px;
  height: 276px;
  border-radius: 100%;
  position: relative;
  top: 5%;
`

const BookInfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: baseline;
`

const BookDescription = styled.p`
  ${({ theme }) => theme.p};
  letter-spacing: ${({ theme }) => theme.commonLetterSpacing};
  margin-top: 20px;
`

const CardContainer = styled.div`
  padding: 25px 50px 5px;
  border: 1px solid #eff2f5;
`

const CardInfoContainer = styled.div`
  display: grid;
  grid-template-columns:
    minmax(auto, 100px) minmax(auto, 200px) minmax(auto, 100px) minmax(auto, 250px)
    repeat(4, 1fr);
  grid-template-rows: repeat(2, 40px);
`

const CardInfoTitle = styled.div`
  ${({ theme }) => theme.h8};
  color: ${({ theme }) => theme.neutralDarkColor};
  letter-spacing: 0.02em;
  font-size: 14px;
  line-height: 21px;
`

const CardInfoText = styled.div`
  font-family: ${({ theme }) => theme.primaryFontFamily};
  font-style: normal;
  color: ${({ theme }) => theme.neutralDarkColor};
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  white-space: nowrap;
  padding-top: 2px;
`

const BookDetail = () => {
  const navigate = useNavigate()

  let { id } = useParams()

  const header = 'Detail'

  const [dataDetail, setDataDetail] = useState<{
    loading: boolean
    initialValues?: {
      cover: string
      categories: any[]
      authors: any[]
      description: string
      name: string
      rentSubscription: {
        price: {
          normalized: string
        }
      }
      saleSubscription: {
        price: {
          normalized: string
        }
      }
      language: {
        languageName: string
      }
      isPublish: boolean
      releaseDate: number
      totalPage: number
    }
  }>({
    loading: true,
  })

  useEffect(() => {
    const fetchData = async () => {
      const res = await bookService.get(id)
      let data = res.data
      data.authors = data.authors.map((item: any) => ({
        ...item,
        authorId: item[getIdentifier()],
      }))
      setDataDetail({
        loading: false,
        initialValues: res.data,
      })
    }

    fetchData()
  }, [])

  if (dataDetail.loading) {
    return <div>loading...</div>
  }

  let allAutors = dataDetail.initialValues.authors
    .map((item) => item.name)
    .join(', ')
  let allCategories = dataDetail.initialValues.categories
    .map((item) => item.name)
    .join(', ')
  let releaseDateFormat = dateTimeformat({
    value: dataDetail.initialValues?.releaseDate,
    format: formatType.date,
  })
  const itemDetail = [
    {
      label: 'Genre',
      render: allCategories,
    },
    {
      label: 'Length',
      render: dataDetail.initialValues.totalPage
        ? dataDetail.initialValues.totalPage + '   pages'
        : 0 + '   pages',
    },
    {
      label: 'SellPrice',
      render: dataDetail.initialValues.saleSubscription?.price.normalized,
    },
    {
      label: 'RentPrice',
      render: dataDetail.initialValues.rentSubscription?.price.normalized,
    },

    {
      label: 'Publish',
      render: dataDetail.initialValues.isPublish ? 'Publish' : 'Unpublish',
    },
    {
      label: 'ReleaseDate',
      render: releaseDateFormat,
    },
    {
      label: 'Language',
      render: dataDetail.initialValues.language?.languageName,
    },
    {
      label: 'Authors',
      render: allAutors,
    },
  ]

  return (
    <FormPageContainer
      headerText={
        <FormPageHeaderText
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ArrowLeftOutlined
            onClick={() => {
              navigate(-1)
            }}
          />
          <div style={{ marginLeft: 16 }}>{header}</div>
        </FormPageHeaderText>
      }
    >
      <Container>
        <BookContainer>
          <BookInfoLeftContainer>
            <BookCoverContainer>
              <BookCoverBackground />
              <BookCover src={dataDetail.initialValues.cover} />
            </BookCoverContainer>
          </BookInfoLeftContainer>

          <BookInfoContainer>
            <BookTitle>
              <BookInfoRow style={{ marginBottom: 15 }}>
                {dataDetail.initialValues.name}
              </BookInfoRow>
            </BookTitle>

            <BookDescription>{dataDetail.initialValues.description}</BookDescription>
            <CardContainer>
              <CardInfoContainer>
                {itemDetail.map((row, index) => (
                  <React.Fragment key={index}>
                    <CardInfoTitle style={{ fontWeight: 'bold' }}>
                      {row.label + ':'}
                    </CardInfoTitle>
                    <CardInfoText>{row.render}</CardInfoText>
                  </React.Fragment>
                ))}
              </CardInfoContainer>
            </CardContainer>
          </BookInfoContainer>
        </BookContainer>
      </Container>
    </FormPageContainer>
  )
}

export default BookDetail
